<template lang="pug">
div(v-if='Inicio')
    v-divider.primario.divider_inicial
    //-PC
    v-container(fluid).d-none.d-md-block.negro
        v-row.mt-12
        v-row.mt-12.ml-3
            div.rectangulo.secundario.mr-5.mt-2
            h1(v-html='Inicio.clientes_satisfechos.título').white--text.font-weight-light.mb-3.pb-3
        v-row.mt-12
        div( v-if='Inicio') 
            v-slide-group(v-model='model' show-arrows=true center-active 
                prev-icon='fas fa-chevron-circle-left' next-icon='fas fa-chevron-circle-right').my-slider
                v-slide-item(v-for='{nombre, foto, cargo, comentario,enlace,icono}, k in Inicio.clientes_satisfechos.carrusel_clientes' :key='k' 
                    v-slot:default="{ active, toggle }"  :value='k'  cols=12 md=4 )
                        v-col().mr-2
                            
                            v-card( style='position: relative' max-width=395 height='430px').negro.elevation-0.ml-7
                                v-row.justify.center
                                    v-img(:src='foto' height='100px'  contain ).imagen_borde.my-3
                                v-row(align='center' justify='center').mt-3
                                    h5(v-html='nombre' style='line-height : 40px;').white--text
                                v-row(align='center' justify='center').mb-3
                                    h5(v-html='cargo' style='line-height : 50px;').grey--text.font-weight-light
                                v-row.text-justify                       
                                    h4(v-html='comentario' style='line-height : 25px; ').white--text.font-weight-light
                                v-row(style='position: absolute; right: 0px; left: 0px; margin: 0 auto; bottom: 10px').justify-center.align-center  
                                    v-btn(icon='' :href='enlace' target='_blank')
                                        v-icon fab fa-facebook
                
        v-row.mb-12
        v-row.mb-12
    //-Móvil
    v-container(fluid).d-md-none.negro
        v-row.mt-12
        v-row.mt-12.ml-3
            div.rectangulo.secundario.mr-5.mt-2
            h2(v-html='Inicio.clientes_satisfechos.título').white--text.font-weight-light.mb-3.pb-3.mt-1
        v-row.mt-12
        v-row.mt-12
        v-slide-group(v-model='model' show-arrows=true center-active 
            prev-icon='fas fa-chevron-circle-left' next-icon='fas fa-chevron-circle-right').my-slider
            v-slide-item(v-for='{nombre, foto, cargo, comentario,enlace,icono}, i in Inicio.clientes_satisfechos.carrusel_clientes' 
            :key='i' v-slot:default="{ active, toggle }"  :value='i' )
                v-card( style='position: relative' height='500px' width=235 cols=12 ).negro.elevation-0.ml-6.mr-6
                    v-row.justify.center
                        v-img(:src='foto' height='100px'  contain ).imagen_borde.my-3
                    v-row(align='center' justify='center').mt-5
                        h6(v-html='nombre' style='line-height : 20px; text-align: center').white--text
                    v-row(align='center' justify='center').mb-3
                        h5(v-html='cargo' style='line-height : 50px;').grey--text.font-weight-light
                    v-row.text-justify                       
                        h4(v-html='comentario' style='line-height : 25px; ').white--text.font-weight-light
                    v-row(style='position: absolute; right: 0px; left: 0px; margin: 0 auto; bottom: 10px').justify-center.align-center  
                        v-btn(icon='' :href='enlace' target='_blank')
                            v-icon fab fa-facebook
                            
    
</template>
<script>
export default {
    data: ()=>({
        model: 0,
    }),
}
</script>
<style lang="sass">
    .divider_inicial
        max-height: 10px!important
        height: 9px!important
    .rectangulo
        width: 14px
        height: 33px
    .imagen_borde
        border-radius: 90%!important
</style>
<style>
.my-slider > .v-slide-group__next.theme--light.v-icon
{
    color: white!important;
    font-size: 33px !important;
}

.theme--light.v-icon.v-icon.v-icon {
    color: white !important;
    font-size: 33px !important;
}
</style>